import Logo from "../../assets/img/logo2.png";
import style from "./header.module.scss";
import HeaderButtons from "./HeaderButtons";
import { HomeIcon } from "../../assets/icons/index";
import ReactTooltip from "react-tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { department } = useSelector((state) => state.user.userData);
  const { t } = useTranslation();

  let { pathname } = useLocation();
  const navigate = useNavigate();
  const splitPath = pathname ? pathname.split("/")[1] : null;

  // console.log(splitPath);
  const isAdminPanelHandler = () => {
    if (splitPath === "admin") {
      navigate("/", { replace: true });
    } else {
      navigate("/admin/kullaci-listesi", { replace: true });
    }
  };

  if (splitPath === "admin" && department === "admin") {
    return (
      <div className={style.header}>
        <div className={style.logo}>
          <img src={Logo} alt="logo" style={{ height: "80%" }} />
        </div>
        <div className={style.elements}>
          <ul>
            <li
              data-tip
              data-for="home"
              className={pathname === "/" ? style.active : null}
            >
              <Link to="/">
                <HomeIcon />
              </Link>
            </li>
            <li
              ///admin/kullanici-olustur
              className={
                splitPath
                  ? pathname === "/admin/kullaci-listesi" ||
                    pathname === "/admin/kullanici-olustur"
                    ? style.active
                    : null
                  : null
              }
            >
              <Link to="/admin/kullaci-listesi">Kullancılar</Link>
            </li>
            <li
              ///admin/kullanici-olustur
              className={
                splitPath
                  ? pathname === "/admin/sistem"
                    ? style.active
                    : null
                  : null
              }
            >
              <Link to="/admin/sistem">Sistem</Link>
            </li>
          </ul>
          <div className={style.buttonWrapper}>
            <HeaderButtons
              isAdminPanel={splitPath}
              isAdminPanelHandler={isAdminPanelHandler}
            />
          </div>
        </div>
      </div>
    );
  }

  const handleContactListClick = () => {
    if (window.location.pathname === "/contact-list") {
      window.location.reload();
    }
  };

  return (
    <header className={style.header}>
      <Link to="/">
        <div className={style.logo}>
          <img src={Logo} alt="logo" style={{ height: "80%" }} />
        </div>
      </Link>
      <div className={style.elements}>
        <ReactTooltip id="home" type="warning" place="bottom" effect="solid">
          {t("home")}
        </ReactTooltip>
        <ul>
          <li
            data-tip
            data-for="home"
            className={pathname === "/" ? style.active : null}
          >
            <Link to="/">
              <HomeIcon />
            </Link>
          </li>
          <li
            className={
              splitPath
                ? pathname === "/lead-list" ||
                  splitPath === "lead-detail" ||
                  splitPath === "lead-list-edit" ||
                  splitPath === "create-lead"
                  ? style.active
                  : null
                : null
            }
          >
            <Link to="/lead-list">{t("ilişkiler")}</Link>
          </li>
          <li
            className={
              splitPath
                ? pathname === "/contact-list" ||
                  splitPath === "contact-detail" ||
                  splitPath === "contact-list-edit" ||
                  splitPath === "create-contact"
                  ? style.active
                  : null
                : null
            }
          >
            <Link to="/contact-list" onClick={handleContactListClick}>
              {t("kontaklar")}
            </Link>
          </li>
          {(department === "admin" || department === "ceo") && (
            <li className={pathname === "/kvk-list" ? style.active : null}>
              <Link to="/kvk-list">KVKK</Link>
            </li>
          )}
          <li
            className={
              splitPath === "create-offer" ||
              splitPath === "bid-detail" ||
              splitPath === "bid-list-edit" ||
              splitPath === "bid-list"
                ? style.active
                : null
            }
          >
            <Link to="/bid-list">{t("aktiviteler")}</Link>
          </li>

          {["admin", "ceo", "gm", "sm"].includes(department) && (
            <li className={pathname === "/approval-list" ? style.active : null}>
              <Link to="/approval-list">{t("onaylar")}</Link>
            </li>
          )}
          <li
            className={
              splitPath === "note-list" || splitPath === "note-detail"
                ? style.active
                : null
            }
          >
            <Link to="/note-list">{t("notlar")}</Link>
          </li>

          {/* <li className={pathname === '/credit-module' ? style.active : null}>
            <Link to="/">E-Postalar</Link>
          </li> */}
          {/* <li className={pathname === '/credit-module' ? style.active : null}>
            <Link to="/">Mesajlar</Link>
          </li> */}
          <li
            className={
              pathname === "/inventory-list" ||
              pathname === "/inventory-create" ||
              splitPath === "inventory-detail"
                ? style.active
                : null
            }
          >
            <Link to="/inventory-list">{t("üniteler")}</Link>
          </li>
          <li
            className={
              pathname === "/project-list" ||
              splitPath === "project-detail" ||
              splitPath === "/project-list-edit" ||
              pathname === "/project-create"
                ? style.active
                : null
            }
          >
            <Link to="/project-list">{t("projeler")}</Link>
          </li>
          {(department === "admin" ||
            department === "ceo" ||
            department === "gm" ||
            department === "rd" ||
            department === "sd" ||
            department === "sm" ||
            department === "dms" ||
            department === "mc" ||
            department === "pd") && (
            <li className={pathname === "/raporlar" ? style.active : null}>
              <Link to="/raporlar">{t("raporlar")}</Link>
            </li>
          )}
        </ul>
        <div className={style.buttonWrapper}>
          <HeaderButtons
            isAdminPanel={splitPath}
            isAdminPanelHandler={isAdminPanelHandler}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
