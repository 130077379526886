import { SubHeader, SideFilter } from "../../components";

import style from "./contactlist.module.scss";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { getAllContacts, deleteAContact } from "../../lib/contactsApi";
import Moment from "moment";
import { Link } from "react-router-dom";
import IndeterminateCheckbox from "../../components/table/indeterminateCheckbox/IndeterminateCheckbox";
import {
  ViewIcon,
  /* DeleteIcon, */
  EditIcon,
  OfferIcon,
} from "../../assets/icons/index";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import Table from "../../components/table/customTable/Table";
import ReactTooltip from "react-tooltip";
import ContactPopUp from "./ContactPopUp";

import { useTranslation } from "react-i18next";

const ContactList = () => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [hasFilterData, setHasFilterData] = useState(false);
  const [pagination, setPagination] = useState({
    queryPageIndex: 0,
    queryPageSize: 25,
    totalCount: null,
  });
  const user = useSelector((state) => state.user.userData);
  const { contactSourceTR, contactSourceEN } = useSelector(
    (state) => state.system.contactSource
  );
  const [targetUser, setTargetUser] = useState({
    fullName: "",
    numOfActivities: 0,
    id: "",
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [method, setNewMethod] = useState();
  const [body, setNewBody] = useState();

  useEffect(() => {
    if (hasFilterData) {
      return;
    }
    setIsLoading(true);
    getAllContacts(
      user.token,
      user.userType,
      pagination.queryPageIndex + 1,
      pagination.queryPageSize,
      method,
      body
    )
      .then((res) => {
        res.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setPagination((prev) => ({
          ...prev,
          totalCount: res.pageCount,
        }));
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user.token,
    user.userType,
    pagination.queryPageIndex,
    pagination.queryPageSize,
    hasFilterData,
  ]);

  const columns = useMemo(
    () => [
      {
        id: "selection",
        disableFilters: true,
        groupByBoundary: true,
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox
            isindeterminate={
              getToggleAllRowsSelectedProps().checked ? true : undefined
            }
            ischecked={
              getToggleAllRowsSelectedProps().checked ? true : undefined
            }
            {...getToggleAllRowsSelectedProps()}
          />
        ),
        Cell: ({ row }) => (
          <IndeterminateCheckbox
            ischecked={row.isSelected ? true : undefined}
            {...row.getToggleRowSelectedProps()}
          />
        ),
      },
      {
        Header: t("kontakStatusu"),
        id: "contactStatus",
        accessor: (data) =>
          data.contactStatus === "aktif" ? t("aktif") : t("pasif"),
      },
      {
        Header: t("isim"),
        id: "name",
        accessor: (d) => d.name + " " + d.surname,
      },
      // {
      //   Header: t("isim"),
      //   accessor: "name",
      // },
      // {
      //   Header: t("soyisim"),
      //   accessor: "surname",
      // },
      {
        Header: t("yas"),
        accessor: "age",
      },
      {
        Header: t("telefon"),
        id: "phone",
        accessor: (d) => {
          const template =
            d?.phone?.length === 12
              ? /(\d{2})(\d{3})(\d{3})(\d{4})/
              : d?.phone?.length === 13
              ? /(\d{3})(\d{3})(\d{3})(\d{4})/
              : /(\d{0})(\d{3})(\d{3})(\d{4})/;
          const phone = d.phone?.toString().replace(template, "+$1($2) $3-$4");
          return phone;
        },
      },
      {
        Header: t("gsm"),
        id: "gsm",
        accessor: (d) => {
          const template =
            d?.gsm?.length === 12
              ? /(\d{2})(\d{3})(\d{3})(\d{4})/
              : d?.gsm?.length === 13
              ? /(\d{3})(\d{3})(\d{3})(\d{4})/
              : /(\d{0})(\d{3})(\d{3})(\d{4})/;
          const gsmPhone = d.gsm?.toString().replace(template, "+$1($2) $3-$4");
          return gsmPhone;
        },
      },
      {
        Header: t("cinsiyet"),
        id: "gender",
        accessor: (data) =>
          data.gender
            ? data.gender === "Erkek"
              ? t("erkek")
              : t("kadin")
            : "",
      },
      {
        Header: t("email"),
        accessor: (d) => {
          return d.email.length > 10
            ? d.email.substring(0, 10) + "..."
            : d.email;
        },
      },
      {
        Header: t("il"),
        accessor: "country",
      },
      {
        Header: t("ilce"),
        accessor: "city",
      },

      {
        Header: t("tcno"),
        accessor: "tckn",
      },
      {
        Header: t("vergiNo"),
        accessor: "taxNo",
      },
      {
        Header: t("sirket"),
        accessor: "company",
      },
      {
        Header: t("kurumsalUnvan"),
        accessor: "corporateTitle",
      },
      {
        Header: t("butce"),
        accessor: "budget",
      },
      {
        Header: t("currency"),
        accessor: "currency",
      },
      {
        Header: t("kategorisi"),
        id: "category",
        accessor: (data) =>
          data.category
            ? data.category === "Müşteri"
              ? t("musteri")
              : data.category === "İş Ortağı"
              ? t("isOrtagi")
              : data.category === "Tedarikçi"
              ? t("tedarikci")
              : data.category === "Resmi Görevli"
              ? t("resmiGorevli")
              : data.category === "Diğer"
              ? t("diger")
              : null
            : "",
      },
      {
        Header: t("altKategorisi"),
        id: "shape",
        accessor: (data) =>
          data.shape
            ? data.shape === "Alıcı"
              ? t("alici")
              : data.shape === "Satıcı"
              ? t("satici")
              : data.shape === "Potansiyel Alıcı"
              ? t("potensiyelAlici")
              : data.shape === "Kiracı"
              ? t("kiraci")
              : data.shape === "Kiralayan"
              ? t("kiralayan")
              : data.shape === "Potansiyel Kiracı"
              ? t("potansiyalKiraci")
              : data.shape === "Diğer"
              ? t("diger")
              : null
            : "",
      },
      {
        Header: t("olusturulmaTarihi"),
        id: "createdAt",
        accessor: (d) => {
          return Moment(d.createdAt).local().format("DD.MM.YYYY");
        },
        show: true,
      },
      {
        Header: t("kontakKaynagi"),
        id: "contactSource",
        accessor: (d) =>
          i18n.language === "tr"
            ? contactSourceTR.find((item) => item.value === d.contactSource)
                ?.name
            : i18n.language === "en"
            ? contactSourceEN.find((item) => item.value === d.contactSource)
                ?.name
            : null,
      },
      {
        Header: t("kanal"),
        id: "channel",
        accessor: t("manüel"),
      },
      {
        Header: t("olusturanPersonel"),
        accessor: "owner",
      },
      {
        Header: t("kullanciTipi"),
        id: "userType",
        accessor: (data) =>
          data.type === "Bireysel"
            ? t("bireysel")
            : data.type === "Kurumsal"
            ? t("kurumsal")
            : "",
      },
      {
        Header: t("islemler"),
        accessor: "actions",
        Cell: ({ row }) => (
          <div className={style.icons}>
            <ReactTooltip
              id="view"
              type="warning"
              place="bottom"
              effect="solid"
            >
              {t("kontagiDetayliGor")}
            </ReactTooltip>
            <ReactTooltip
              id="offer"
              type="warning"
              place="bottom"
              effect="solid"
            >
              {t("kontagaAktiviteOlustur")}
            </ReactTooltip>
            <ReactTooltip
              id="edit"
              type="warning"
              place="bottom"
              effect="solid"
            >
              {t("kontagiDuzenle")}
            </ReactTooltip>
            <ReactTooltip
              id="delete"
              type="warning"
              place="bottom"
              effect="solid"
            >
              {t("kontagiSil")}
            </ReactTooltip>
            {(user.department !== "hr" ||
              user.department !== "fd" ||
              user.department !== "pd") && (
              <Link
                target="_blank"
                data-tip
                data-for="view"
                className={style.icon}
                to={`/contact-detail/${row.original._id}`}
              >
                <ViewIcon />
              </Link>
            )}
            {(user.department !== "hr" ||
              user.department !== "fd" ||
              user.department !== "pd" ||
              user.department !== "dms" ||
              user.department !== "mc") && (
              <Link
                target="_blank"
                data-tip
                data-for="offer"
                className={style.icon}
                to={`/create-offer/${row.original._id}`}
              >
                <OfferIcon />
              </Link>
            )}
            {(user.department === "admin" ||
              user.department === "ceo" ||
              user.department === "gm" ||
              user.department === "rd" ||
              user.department === "sd" ||
              user.department === "sm") && (
              <Link
                target="_blank"
                data-tip
                data-for="edit"
                className={style.icon}
                to={`/contact-list-edit/${row.original._id}`}
              >
                <EditIcon />
              </Link>
            )}
            {/*
            {(user.department === "admin" ||
              user.department === "ceo" ||
              user.department === "gm" ||
              user.department === "rd" ||
              user.department === "sd" ||
              user.department === "sm") && (
              <button
                data-tip
                data-for="delete"
                className={style.icon}
                onClick={() => {
                  setTargetUser({
                    fullName: row.original.name + " " + row.original.surname,
                    numOfActivities: row.original.bids.length,
                    id: row.original._id,
                  });
                  setIsPopupOpen(true);
                }}
              >
                <DeleteIcon />
              </button>
            )}
            */}
          </div>
        ),
      },
    ],
    [i18n.language, contactSourceTR, contactSourceEN, t, user.department]
  );

  const {
    getTableProps,
    rows,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    getToggleHideAllColumnsProps,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      pageCount: data.length > 0 ? Math.ceil(pagination.totalCount) : 0,
      manualPagination: hasFilterData ? false : true,
      initialState: {
        pageIndex: pagination.queryPageIndex,
        pageSize: pagination.queryPageSize,
        hiddenColumns: [
          "age",
          "phone",
          "gender",
          "country",
          "city",
          "tckn",
          "taxNo",
          "company",
          "corporateTitle",
          "channel",
          "userType",
          "shape",
          "budget",
          "currency",
          "Email",
          "contactSource",
        ],
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      queryPageIndex: pageIndex,
    }));
  }, [pageIndex]);

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      queryPageSize: pageSize,
    }));
  }, [pageSize]);

  const successClick = () => {
    deleteAContact(user.token, [targetUser.id])
      .then((res) => {
        //console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    window.location.reload();
    //TODO reload atmasi lazim dataya
    setTargetUser({
      fullName: "",
      numOfActivities: 0,
      id: "",
    });
    setIsPopupOpen(false);
  };

  const popUpClose = () => {
    setTargetUser({
      fullName: "",
      numOfActivities: 0,
      id: "",
    });
    setIsPopupOpen(false);
  };

  return (
    <>
      {isPopupOpen && (
        <ContactPopUp
          closeClick={popUpClose}
          successClick={successClick}
          fullName={targetUser.fullName}
          numOfActivities={targetUser.numOfActivities}
        />
      )}
      <div className={style.contactList}>
        <SubHeader
          contactMenu
          contacData={data}
          contactRows={rows}
          getToggleHideAllColumnsPropsForContacts={getToggleHideAllColumnsProps}
          ContactAllColumns={allColumns}
        />
        <div className={style.content}>
          <SideFilter
            setContactData={setData}
            setHasFilterData={setHasFilterData}
            getPagination={pagination}
            setPagination={setPagination}
            setNewMethod={setNewMethod}
            setNewBody={setNewBody}
          />
          <Table
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            page={page}
            prepareRow={prepareRow}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
            pageSize={pageSize}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default ContactList;
