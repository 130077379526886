import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Container, Grid, Tabs, Tab, Typography } from "@mui/material";
import { TotalContact } from "../../components/dashboard/total-contact";
import { TotalSalesInventory } from "../../components/dashboard/total-sales-inventory";
import { TotalRentInventory } from "../../components/dashboard/total-rent-inventory";
import { TotalUser } from "../../components/dashboard/total-user";
import { BidDailyProject } from "../../components/dashboard/bid-daily-project";
import { BidDailyStage } from "../../components/dashboard/bid-daily-stage";
import { DashboardLayout } from "../../components/dashboard/dashboard-layout";
import { BaseUrl } from "../../ApiConfig";
import Loader from "../../components/ui/Loader/Loader";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const Dashboard = () => {
  const user = useSelector((state) => state.user.userData);
  const [loading, setLoading] = useState(true);

  const [contanctInfo, setContactInfo] = useState({
    contactCount: 0,
    dailyContactCount: 0,
    lastWeekRange: 0,
    percentageChange: 0,
  });
  const [userInfo, setUserInfo] = useState({
    userCount: 0,
    personnelDistribution: [],
  });
  const [inventorySalesInfo, setInventorySalesInfo] = useState({
    inventoryCount: 0,
    salesRate: 0,
  });
  const [inventoryRentInfo, setInventoryRentInfo] = useState({
    inventoryCount: 0,
    salesRate: 0,
  });

  const [activityDailyInfo, setActivityDailyInfo] = useState({
    personelProjects: null,
    activityStage: null,
    personelProjectsDetails: {},
    activityStageDetails: {},
  });

  const fetchContactInfo = async () => {
    axios
      .get(`${BaseUrl}/api/dashboard/get-contacts-info`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setContactInfo({
          contactCount: res.data.contactCount,
          dailyContactCount: res.data.dailyContactCount,
          lastWeekContactCount: res.data.lastWeekContactCount,
          percentageChange: res.data.percentageChange,
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const fetchUserInfo = async () => {
    axios
      .get(`${BaseUrl}/api/dashboard/get-users-info`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setUserInfo({
          userCount: res.data.userCount,
          personnelDistribution: res.data.personnelDistribution,
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const fetchInventorySalesInfo = async () => {
    axios
      .get(`${BaseUrl}/api/dashboard/get-inventory-sales-info`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setInventorySalesInfo({
          inventoryCount: res.data.inventoryCount,
          salesRate: res.data.salesRate,
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const fetchInventoryRentInfo = async () => {
    axios
      .get(`${BaseUrl}/api/dashboard/get-inventory-rent-info`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setInventoryRentInfo({
          inventoryCount: res.data.inventoryCount,
          salesRate: res.data.salesRate,
        });
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [period, setPeriod] = useState("daily");

  const fetchDailyBidInfo = async (period = "daily") => {
    setLoading(true);
    setPeriod(period);
    axios
      .get(`${BaseUrl}/api/dashboard/get-daily-bid-info?period=${period}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        setActivityDailyInfo({
          personelProjects: res.data.personelProjects,
          activityStage: res.data.activityStage,
          personelProjectsDetails: res.data.personelProjectsDetails,
          activityStageDetails: res.data.activityStageDetails,
        });
      })
      .catch((err) => {
        console.log(err.response);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchContactInfo(),
        fetchUserInfo(),
        fetchInventorySalesInfo(),
        fetchInventoryRentInfo(),
        fetchDailyBidInfo(),
      ]);
      setLoading(false); // Yükleme tamamlandığında
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [valueBidProjectMain, setValueBidProjectMain] = useState(0);
  const [valueBidProjectSub, setValueBidProjectSub] = useState(0);
  const [valueActivityStageSub, setValueActivityStageSub] = useState(0);

  const handleChangeBidProjectMain = (event, newValue) => {
    setValueBidProjectMain(newValue);
    let period;
    switch (newValue) {
      case 1:
        period = "weekly";
        break;
      case 2:
        period = "monthly";
        break;
      case 3:
        period = "yearly";
        break;
      case 0:
      default:
        period = "daily";
        break;
    }
    fetchDailyBidInfo(period);
    setValueBidProjectSub(0); // Ana tab değiştiğinde, alt tabı varsayılan olarak "Hepsi"ne getir
    setValueActivityStageSub(0);
  };

  const handleChangeBidProjectSub = (event, newValue) => {
    setValueBidProjectSub(newValue);
  };

  const handleChangeActivityStageSub = (event, newValue) => {
    setValueActivityStageSub(newValue);
  };

  const combineAllProjectsData = (projectsDetails) => {
    const combinedData = {};
    for (const project in projectsDetails) {
      for (const key in projectsDetails[project]) {
        if (combinedData[key]) {
          combinedData[key] += projectsDetails[project][key];
        } else {
          combinedData[key] = projectsDetails[project][key];
        }
      }
    }
    return combinedData;
  };

  const combineAllStagesData = (stagesDetails) => {
    const combinedData = {};
    for (const stage in stagesDetails) {
      for (const key in stagesDetails[stage]) {
        if (combinedData[key]) {
          combinedData[key] += stagesDetails[stage][key];
        } else {
          combinedData[key] = stagesDetails[stage][key];
        }
      }
    }
    return combinedData;
  };

  const renderProjectTabs = (projectsDetails) => {
    const projectNames = ["Hepsi", ...Object.keys(projectsDetails).sort()];
    return (
      <Tabs
        value={valueBidProjectSub}
        onChange={handleChangeBidProjectSub}
        aria-label="sub tabs"
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          justifyContent: "center",
        }}
      >
        {projectNames.map((projectName, index) => (
          <Tab label={projectName} {...a11yProps(index)} key={index} />
        ))}
      </Tabs>
    );
  };

  const renderProjectContent = (projectsDetails) => {
    const combinedProjects = combineAllProjectsData(projectsDetails);
    const projectNames = ["Hepsi", ...Object.keys(projectsDetails).sort()];
    return projectNames.map((projectName, index) => (
      <TabPanel value={valueBidProjectSub} index={index} key={index}>
        <BidDailyProject
          period={period}
          personelProjects={
            projectName === "Hepsi"
              ? combinedProjects
              : projectsDetails[projectName]
          }
        />
      </TabPanel>
    ));
  };

  const renderActivityStageTabs = (activityStageDetails) => {
    const stageNames = ["Hepsi", ...Object.keys(activityStageDetails).sort()];
    return (
      <Tabs
        value={valueActivityStageSub}
        onChange={handleChangeActivityStageSub}
        aria-label="activity stage tabs"
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          justifyContent: "center",
        }}
      >
        {stageNames.map((stageName, index) => (
          <Tab label={stageName} {...a11yProps(index)} key={index} />
        ))}
      </Tabs>
    );
  };

  const renderActivityStageContent = (activityStageDetails) => {
    const combinedStages = combineAllStagesData(activityStageDetails);
    const stageNames = ["Hepsi", ...Object.keys(activityStageDetails).sort()];
    return stageNames.map((stageName, index) => (
      <TabPanel value={valueActivityStageSub} index={index} key={index}>
        <BidDailyStage
          period={period}
          activityStage={
            stageName === "Hepsi"
              ? combinedStages
              : activityStageDetails[stageName]
          }
        />
      </TabPanel>
    ));
  };

  return loading ? (
    <Loader isLoading={loading} />
  ) : (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
          minWidth: 400,
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item xl={3} lg={3} sm={6} xs={12}>
              <TotalContact contanctInfo={contanctInfo} />
            </Grid>
            <Grid item xl={3} lg={3} sm={6} xs={12}>
              <TotalSalesInventory inventorySalesInfo={inventorySalesInfo} />
            </Grid>
            <Grid item xl={3} lg={3} sm={6} xs={12}>
              <TotalRentInventory inventoryRentInfo={inventoryRentInfo} />
            </Grid>
            <Grid item xl={3} lg={3} sm={6} xs={12}>
              <TotalUser userInfo={userInfo} />
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <Tabs
                value={valueBidProjectMain}
                onChange={handleChangeBidProjectMain}
                aria-label="bid daily project tabs"
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  justifyContent: "center",
                }}
              >
                <Tab label="Günlük" {...a11yProps(0)} />
                <Tab label="Haftalık" {...a11yProps(1)} />
                <Tab label="Aylık" {...a11yProps(2)} />
                <Tab label="Yıllık" {...a11yProps(3)} />
              </Tabs>
              <TabPanel value={valueBidProjectMain} index={0}>
                {renderProjectTabs(activityDailyInfo.personelProjectsDetails)}
                {renderProjectContent(
                  activityDailyInfo.personelProjectsDetails
                )}
              </TabPanel>
              <TabPanel value={valueBidProjectMain} index={1}>
                {renderProjectTabs(activityDailyInfo.personelProjectsDetails)}
                {renderProjectContent(
                  activityDailyInfo.personelProjectsDetails
                )}
              </TabPanel>
              <TabPanel value={valueBidProjectMain} index={2}>
                {renderProjectTabs(activityDailyInfo.personelProjectsDetails)}
                {renderProjectContent(
                  activityDailyInfo.personelProjectsDetails
                )}
              </TabPanel>
              <TabPanel value={valueBidProjectMain} index={3}>
                {renderProjectTabs(activityDailyInfo.personelProjectsDetails)}
                {renderProjectContent(
                  activityDailyInfo.personelProjectsDetails
                )}
              </TabPanel>
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <div style={{ minHeight: "48px" }} />
              {renderActivityStageTabs(activityDailyInfo.activityStageDetails)}
              {renderActivityStageContent(
                activityDailyInfo.activityStageDetails
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

Dashboard.getLayout = (page) => <DashboardLayout>{page}</DashboardLayout>;

export default Dashboard;
