/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { ProcessButton, NewButton } from "./Buttons";
import style from "./subheader.module.scss";
import axios from "axios";
import Popup from "../LeadElements/Popup";
import { useNavigate } from "react-router";
//import { selectKvkk } from "../../redux/actions";
import { BaseUrl } from "../../ApiConfig";
import ExcelExportPopup from "./ExcelExportPopup.jsx";
import { DownIcon, UpIcon } from "../../assets/icons";
import IndeterminateCheckbox from "../table/indeterminateCheckbox/IndeterminateCheckbox";
import { useSelector } from "react-redux";
import { sendLeadToContact } from "../../lib/leadApi";

import { useTranslation } from "react-i18next";

const CheckBox = ({ getToggleHideAllColumnsProps, allColumns }) => {
  const [select, setSelect] = useState(false);
  const { t } = useTranslation();

  const checkboxMenu = useRef(null);
  const closeOpenMenus = (e) => {
    if (
      checkboxMenu.current &&
      select &&
      !checkboxMenu.current.contains(e.target)
    ) {
      setSelect(false);
    }
  };
  document.addEventListener("mousedown", closeOpenMenus);

  return (
    <div className={style.checkboxContact} ref={checkboxMenu}>
      <button className={style.button} onClick={() => setSelect(!select)}>
        {t("kolonSeciniz")}
        {select ? <UpIcon /> : <DownIcon />}
      </button>
      {select ? (
        <div className={style.select}>
          <div>
            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
            {t("hepsiniSec")}
          </div>
          {allColumns.map((column) => (
            <div key={column.id}>
              <label>
                <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
                {column.Header}
              </label>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const LeadMenu = ({
  leadData,
  getToggleHideAllColumnsProps,
  allColumns,
  leadRows,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const [selectValue, setSelectValue] = useState();
  const [popup, setPopup] = useState(false);
  const [kvkPopup, setKvkPopup] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [excelPopup, setExcelPopup] = useState(false);
  const [headers, setHeaders] = useState([]);

  const [count, setCount] = useState(0);
  const [type, setType] = useState("");
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState("");

  const successModalHandler = () => {
    let arrayids = [];
    leadRows.forEach((element) => {
      if (element.isSelected) {
        arrayids.push(element.original._id);
      }
    });
    if (type === "delete") {
      axios
        .delete(`${BaseUrl}/api/lead/delete/${arrayids}`)
        .then((res) => {
          //console.log(res);
          window.location.reload();
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
          //TODO refresh page problem after updating data look in to it later on
        })
        .catch((err) => console.log(err));
    } else {
      sendLeadToContact(user.token, arrayids, user.userType)
        .then((res) => {
          //console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setType("");
    setPage("");
    setIsError(false);
    setPopup(false);
  };

  useEffect(() => {
    if (selectValue === "delete") {
      setPopup(true);
      setType("delete");
      setPage("lead");
      const isElelemtSelected = leadRows.some((row) => row.isSelected);
      if (isElelemtSelected) {
        setCount(leadRows.filter((d) => d.isSelected).length);
      } else {
        setIsError(true);
      }
      setSelectValue("default");
    }
    if (selectValue === "kalifiye") {
      setPopup(true);
      setPage("lead");
      setType("kalifiye");
      const isElelemtSelected = leadRows.some((row) => row.isSelected);
      if (isElelemtSelected) {
        setCount(leadRows.filter((d) => d.isSelected).length);
      } else {
        setIsError(true);
      }

      setSelectValue("default");
      //pushContact();
    }
    // if (selectValue === "kvkk") {
    //   dispatch(
    //     selectKvkk(
    //       leadData.filter((item) => item.select).map((item) => item.kvkkId)
    //     )
    //   );
    // }
  }, [selectValue]);

  const excelExportAndDownload = (data) => {
    setExcelPopup(true);
    setHeaders([
      t("isim"),
      t("soyisim"),
      t("telefon"),
      t("email"),
      t("not"),
      t("sirket"),
      t("olusturulmaTarihi"),
      t("olusturanPersonel"),
      t("kullanciTipi"),
    ]);

    let isim = t("isim");
    let soyisim = t("soyisim");
    let telefon = t("telefon");
    let eposta = t("email");
    let not = t("not");
    let sirket = t("sirket");
    let olusturulmaTarihi = t("olusturulmaTarihi");
    let olusturanPersonel = t("olusturanPersonel");
    let kullanciTipi = t("kullanciTipi");

    const newData = data.map((item) => {
      return {
        [isim]: item.name,
        [soyisim]: item.surname,
        [telefon]: item.phone,
        [eposta]: item.email,
        [not]: item.note,
        [sirket]: item.company,
        [olusturulmaTarihi]: item.createdAt,
        [olusturanPersonel]: item.owner,
        [kullanciTipi]: item.userType,
      };
    });
    setExcelData(newData);
  };
  const exelDownloaded = () => {
    setExcelPopup(false);
  };

  const closeModalHandler = () => {
    setType("");
    setPage("");
    setIsError(false);
    setPopup(false);
  };

  return (
    <>
      {popup ? (
        <Popup
          closeClick={closeModalHandler}
          count={count}
          type={type}
          page={page}
          isError={isError}
          successClick={successModalHandler}
        />
      ) : null}

      {excelPopup ? (
        <ExcelExportPopup
          closeClick={exelDownloaded}
          headers={headers}
          paddingVal="40px 10px"
          data={excelData}
          fileName={`${t("iliskiler")}`}
          text={t("excelExport")}
        />
      ) : null}

      {kvkPopup ? (
        <Popup
          closeClick={setKvkPopup}
          isKvkExistTitle={kvkPopup}
          //successClick={deleteCustomerByIds}
        />
      ) : null}

      <div className={style.leadMenu}>
        <div className={style.left}>
          <div
            style={{
              marginLeft: "26px",
              width: "100%",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            {t("iliskiler")}
          </div>
        </div>
        <div className={style.right}>
          <CheckBox
            getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            allColumns={allColumns}
          />
          {/* {(user.department === "admin" || user.department === "ceo") && (
            <NewButton
              title={t("topluVeriYukle")}
              // onClick={() => window.location.replace("/create-lead")}
            />
          )} */}
          {(user.department === "admin" || user.department === "ceo") && (
            <NewButton
              title={t("veriyiDisaAktar")}
              icon={"exel"}
              onClick={() => {
                excelExportAndDownload(leadData);
              }}
            />
          )}
          {(user.department !== "hr" ||
            user.department !== "fd" ||
            user.department !== "mc" ||
            user.department !== "dms" ||
            user.department !== "pd") && (
            <NewButton
              title={t("yeniIliskiEkle")}
              onClick={() => {
                navigate("/create-lead", { reaplce: true });
                // window.location.replace('/create-lead')
              }}
            />
          )}
          {(user.department === "admin" ||
            user.department === "ceo" ||
            user.department === "gm" ||
            user.department === "sd" ||
            user.department === "rd" ||
            user.department === "sm") && (
            <ProcessButton
              title={t("cokluIslemler")}
              selectValue={selectValue}
              setSelectValue={setSelectValue}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default LeadMenu;
